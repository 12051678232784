import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import howItWork from '../../assets/images/how-its-work.png'
import plantP from '../../assets/images/plantP.png'
import plantL from '../../assets/images/plantL.png'
import plantA from '../../assets/images/plantA.png'
import plantN from '../../assets/images/plantN.png'
import plantT from '../../assets/images/plantT.png'
import osAboutGraphic1 from '../../assets/images/osAboutGraphic1.png'

const PlantMission = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" />
                                Mission
                            </span>
                            <h2>Our P.L.A.N.T. Mission</h2>
                            <div className="inner-box">
                                <div className="single-item">
                                  <div className="count-box">
                                    <img src={plantP} alt="banner" />
                                  </div>
                                    <h3>People</h3>
                                    <p className="brighter-p">The future of cannabis is inclusive. Outspoke supports equal rights and opportunities for all people to have their voices heard.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                      <img src={plantL} alt="banner" />
                                    </div>
                                    <h3>Legacy Farms</h3>
                                    <p className="brighter-p">As the heart of cannabis, Legacy Farms turned an age-old plant into countless applications with invaluable benefit to humanity. We strongly support legacy farms and artisans.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                      <img src={plantA} alt="banner" />
                                    </div>
                                    <h3>Armed Forces & Veterans</h3>
                                    <p className="brighter-p">To military veterans that dedicate their lives in service of others - thank you. We strive to ensure veterans are better respected and protected.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                      <img src={plantN} alt="banner" />
                                    </div>
                                    <h3>National Decriminalization</h3>
                                    <p className="brighter-p">As we push into the frontier of legalization, we must continue to be inclusive of those impacted by cannabis criminalization</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                      <img src={plantT} alt="banner" />
                                    </div>
                                    <h3>Turnout At The Polls</h3>
                                    <p className="brighter-p">Driving engagement and participation in our elections shapes government into truly reflecting the people's best interests.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image" style={{textAlign: 'center'}}>
                            <img src={osAboutGraphic1} alt="banner" style={{width: '60%', borderRadius: 6}}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PlantMission
